<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingInvestmentSurvey',
  components: {
    Intro: lazyLoad('views/Onboarding/InvestmentSurvey/Intro'),
    Products: lazyLoad('views/Onboarding/InvestmentSurvey/Products'),
    Services: lazyLoad('views/Onboarding/InvestmentSurvey/Services'),
    Overview: lazyLoad('views/Onboarding/InvestmentSurvey/Overview'),
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    component() {
      return this.$route.params.step;
    },
    formView() {
      const noForms = new Set([
        'Intro',
        'Overview',
      ]);
      return !noForms.has(this.step);
    },
  },
  watch: {
    subStage(step) {
      if (step !== 'Acknowledgement') {
        this.$router.push({
          name: 'Onboarding.InvestmentSurvey',
          params: {
            step,
          },
        });
      }
    },
  },
  methods: {
    ...mapActions('user', [
      'setMainStage',
      'setSubStage',
      'updateOnboarding',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async submitForm() {
      await this.$refs.form.submit();
      this.setLoading(false);
    },
    previous(step) {
      this.$refs.form.previous(step);
    },
    async next() {
      this.setLoading(true);
      if (this.component === 'Intro') {
        const input = {
          investor: {},
          mainStage: 'investmentSurvey',
          subStage: 'intro',
        };
        await this.updateOnboarding({
          input,
        });
        this.setLoading(false);
        return this.setSubStage('Products');
      }
      if (this.component === 'Overview') {
        const input = {
          investor: {},
          mainStage: 'investmentSurvey',
          subStage: 'overview',
        };
        const nextStage = await this.updateOnboarding({
          input,
        });
        this.setMainStage(nextStage.nextMainStage);
        this.setSubStage(nextStage.nextSubStage);
        this.setLoading(false);
        return this.$router.push({
          name: 'Onboarding.Overview',
        });
      }
      return this.submitForm();
    },
  },
};
</script>

<style>
.v-input__slider {
  font-size: 12px !important;
}
</style>
